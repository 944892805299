import { navigate } from '@reach/router'
import axios from 'axios'
import * as Sentry from '@sentry/browser'
import {updateToken, login as sessionLogin} from '../util/auth'
import {getImpact} from './impact'
import {getError} from './error'
import {setOrganization} from './discover'

import {
	LOGIN_FAILURE,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	RESET_ACCESS_TOKEN,
	RESET_ACCESS_TOKEN_SUCCESS,
	RESET_ACCESS_TOKEN_FAIL,
	REGISTER_REQUEST,
	REGISTER_FAILURE,
	LOGOUT,
	MESSAGE,
	CLEAR,
	CLEAR_AUTH,
	FORGOT_REQUEST,
	FORGOT_SUCCESS,
	FORGOT_FAILURE,
	RESET_REQUEST,
	RESET_SUCCESS,
	RESET_FAILURE,
	API,
	TEST_HEADER,
} from './types'
import { trackEvent } from '../util'

/*
Authentication actions that interact with backend
*/

// Action to log user in
export function login(email, password, setSession, cleanSession, redirect) {
	cleanSession()
	localStorage.clear()
	clearAuth()

	return dispatch => {
		dispatch({
			type: LOGIN_REQUEST,
		})

 		axios.post(`${API}/v1/mobile/updated/firebase/auth/sign_in`, {email, password})
			.then(res => {
				const {
					first_name,
					last_name,
					user_id
				} = res.data
				const access_token = `Bearer ${res.data.access_token}`
				const userName = `${first_name} ${last_name}`
				setSession({...res.data, access_token, role: 1, userName, email, user_id})

				trackEvent('login', {email})
				dispatch({
					type: LOGIN_SUCCESS,
					accessToken: access_token,
					userName,
					role: 1,
					user: res.data
				})

				dispatch({
					type: MESSAGE,
					message: 'Successful login',
				})


				dispatch(getImpact(access_token))
				Sentry.configureScope((scope) => {
					scope.setUser({"email": email})
				  })
				navigate(redirect)
			})
			.catch(error => {
				try{
					dispatch({
						type: LOGIN_FAILURE,
						error: error.response.data.error_message
					})
				} catch(err) {
					dispatch({
						type: LOGIN_FAILURE,
						error: 'Error loggin in. Please try again or email support@flourishchange.com',
					})
				}
				if (error.response.status > 400) {
					Sentry.captureException(error)
				}
			})
	}
}

// Action to refresh access Token

export const refreshToken = () => (dispatch) => {
	dispatch({
		type: RESET_ACCESS_TOKEN
	})

	if (JSON.parse(localStorage.getItem('expires_at')) > new Date().getTime()) {
		return new Promise((resolve) => resolve(localStorage.getItem('access_token')))
	}

	return axios.post(`https://securetoken.googleapis.com/v1/token?key=${process.env.firebaseKey}`,
		{
			grant_type: "refresh_token",
			refresh_token: localStorage.getItem('refresh_token')
		})
		.then((res) => {
			updateToken(`Bearer ${res.data.id_token}`)
			dispatch({
				type: RESET_ACCESS_TOKEN_SUCCESS,
				payload: `Bearer ${res.data.id_token}`
			})
			return `Bearer ${res.data.id_token}`
		})
		.catch((err) => {
			if (err.response.status > 400) {
				Sentry.captureException(err)
			}
			dispatch({
				type: RESET_ACCESS_TOKEN_FAIL,
				payload: err
			})
		})
}

// Action to handle user sign up process
export function register(data) {
	return dispatch => {
		dispatch({
			type: REGISTER_REQUEST,
		})

		const {email, password, organization_id, project_ids} = data

		axios.post(`${API}/v1/mobile/updated/firebase/auth/sign_up`, data, {
			headers: {
				'Content-Type': "application/json; charset=utf-8",
				'flourish-test-header': TEST_HEADER,
			}
		}).then((res) => {
			const token = `Bearer ${res.data.access_token}`
			trackEvent('user_sign_up', {uid: res.data.user_id})
			dispatch(setOrganization(organization_id, project_ids, token, false))
		}).then(()=>dispatch(sessionLogin(email, password, '/register/monthlyMin/')))
			.catch(e => {
				if (e.response.status > 400) {
					Sentry.captureException(e)
				}
				dispatch({
					type: REGISTER_FAILURE,
					error: getError(e) || 'Error registering user',
				})
			})
	}
}

// Action for when user forgets password, sends them a reset email
export function forgot(email) {
	return dispatch => {
		dispatch({
			type: FORGOT_REQUEST,
		})

		fetch(`${API}/v1/mobile/forgot_password`, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': "application/json; charset=utf-8",
				'flourish-test-header': TEST_HEADER,
			},
			method: 'POST',
			body: JSON.stringify({
				email,
				web_form_url: `/reset/?email=${email}`,
			}),
		}).then((res) => {
			if (!res.ok) {
				dispatch({
					type: FORGOT_FAILURE,
					error: "Enter a valid email address.",
				})
			}

			return res.json()
		}).then(() => {
			dispatch({
				type: FORGOT_SUCCESS,
			})

			dispatch({
				type: MESSAGE,
				message: 'Email sent',
			})
		}).catch(e => {
			dispatch({
				type: FORGOT_FAILURE,
				error: getError(e) || 'Enter a valid email address.',
			})
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
		})
	}
}

// Action for when user forgets password, allows them to reset password
export function reset(email, token, password) {
	return dispatch => {
		dispatch({
			type: RESET_REQUEST,
		})

		axios.post(`${API}/v1/user/reset_password`, {
			email,
			token,
			password,
		}, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': "application/json; charset=utf-8",
				'flourish-test-header': TEST_HEADER,
			},
		}).then(() => {
			dispatch({
				type: RESET_SUCCESS,
			})
			dispatch({
				type: MESSAGE,
				message: 'Password reset',
			})
		}).catch(e => {
			Sentry.captureException(e)
			dispatch({
				type: RESET_FAILURE,
				error: getError(e) || 'Uh Oh! There was an issue resetting your password. Please email support@flourishchange.com to resolve.',
			})
		})
	}
}

// Action to log a user out
export const logout = (redirect) => {
	trackEvent('logout')
	localStorage.clear()
	let url = '/login/'
	if (redirect) {
		url = `/login/?redirect=${redirect}`
	}
	Sentry.configureScope(scope => scope.setUser({}))
	navigate(url)
	return {
		type: LOGOUT,
	}
}

// Action to clear auth redux state
export const clearAuth = () => {
	return {
		type: CLEAR_AUTH,
	}
}

// Action to clear redux state
export const clear = () => {
	return {
		type: CLEAR,
	}
}
