/**
 * Reducer which handles all events related to user actions
 */

// Import action types
import {
	EXCHANGE_TOKEN_SUCCESS,
	GET_ACCOUNTS_SUCCESS,
	GET_ACCOUNTS_FAIL,
	GET_ACCOUNTS_REQUEST,
	GET_IMPACT_REQUEST,
	GET_IMPACT_SUCCESS,
	GET_IMPACT_FAIL,
	SET_PROJECTS_FAIL,
	SET_PROJECTS_REQUEST,
	SET_PROJECTS_SUCCESS,
	GET_PROFILE_REQUEST,
	GET_PROFILE_SUCCESS,
	GET_PROFILE_FAIL,
	USER_LOADING,
	USER_ERROR,
	USER_SUCCESS,
	SET_MAX_REQUEST,
	SET_MIN_REQUEST,
	SET_MAX_SUCCESS,
	SET_MIN_SUCCESS,
	SET_ROUNDUP_REQUEST,
	SET_ROUNDUP_SUCCESS,
	SET_ROUNDUP_FAIL,
	SET_MAX_FAIL,
	SET_MIN_FAIL,
} from '../factory/types'

const initialState = {
	token: '',
	accountList: [],
	accountsRecieved: false,
	project_pending: false,
	project_error: '',
	projects: [],
	organization: {},
	error: '',
	success: {},
	pending: false,
	donor_type: null,
	month_donation_limit: null,
	daily_donation: null,
	current_month_donations_sum: null,
	zip_code: null,
	monthly_min_donation_limit: null,
	profile_pending: false
}

const userReducer = (state = initialState, action) => {
	switch (action.type) {
	/*
		Cases for user management
		*/
	case EXCHANGE_TOKEN_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.token = action.payload
		return newState
	}
	case GET_IMPACT_REQUEST:
	case GET_ACCOUNTS_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		newState.accountsRecieved = false
		return newState
	}
	case SET_MAX_REQUEST:
	case SET_MIN_REQUEST:
	case SET_ROUNDUP_REQUEST:
	case GET_PROFILE_REQUEST: {
		const newState = Object.assign({}, state)
		newState.profile_pending = true
		newState.profile_error = ""
		return newState
	}
	case GET_PROFILE_SUCCESS: {
		const newState = Object.assign({}, state, action.payload)
		newState.accountList = action.payload.accounts
		newState.accountsRecieved = true
		newState.profile_pending = false
		return newState
	}
	case SET_MAX_FAIL:
	case SET_MIN_FAIL:
	case SET_ROUNDUP_FAIL:
	case GET_PROFILE_FAIL: {
		const newState = Object.assign({}, state)
		newState.profile_pending = false
		newState.profile_error = action.payload
		return newState
	}
	case GET_ACCOUNTS_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.accountList = [] // action.payload
		newState.accountsRecieved = true
		newState.pending = false
		return newState
	}
	case GET_ACCOUNTS_FAIL: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.accountsRecieved = true
		newState.error = 'unable to get accounts'
		return newState
	}
	case USER_LOADING: {
		const newState = Object.assign({}, state)
		newState.loading = true
		newState.error = ''
		return newState
	}
	case USER_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.success[action.payload] = true
		newState.loading = false
		return newState
	}
	case GET_IMPACT_FAIL:
	case USER_ERROR: {
		const newState = Object.assign({}, state)
		newState.error = action.payload
		newState.loading = false
		newState.pending = false
		return newState
	}
	case GET_IMPACT_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.organization = action.payload.organization
		newState.user = action.payload.user
		newState.projects = action.payload.projects
		newState.pending = false
		return newState
	}

	// SET/GET PROJECTS
	case SET_PROJECTS_REQUEST: {
		const newState = Object.assign({}, state)
		newState.project_error = ''
		newState.project_pending = true
		return newState
	}
	case SET_PROJECTS_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.project_pending = false
		newState.projects = action.payload.projects_ids
		return newState
	}
	case SET_PROJECTS_FAIL: {
		const newState = Object.assign({}, state)
		newState.project_pending = false
		newState.project_error = action.payload
		return newState
	}

	default:
		return state
	}
}

export default userReducer
