import axios from 'axios'
import * as Sentry from '@sentry/browser'


import {
	GET_ROUNDUPS_REQUEST,
	GET_ROUNDUPS_SUCCESS,
	GET_ROUNDUPS_FAIL,
	API,
	TEST_HEADER,
} from './types'

export const getAccountPerformance = (token) => (dispatch) => {
	dispatch({type: GET_ROUNDUPS_REQUEST})

	axios.get(`${API}/v1/mobile/updated/account_performance`, {headers: {authorization: token, flourish_test_header: TEST_HEADER}})
		.then((res) => {
			dispatch({type: GET_ROUNDUPS_SUCCESS, payload: res.data})
		})
		.catch((e) => {
			console.log('error doing something: ', e)
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
			dispatch({type: GET_ROUNDUPS_FAIL, payload: 'There was an error getting your account data. Please try again. '})
		})
}