/**
 * Reducer which handles all events related to user authentication process
 */

// Import action types
import {
	LOGIN_FAILURE,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	RESET_ACCESS_TOKEN,
	RESET_ACCESS_TOKEN_FAIL,
	RESET_ACCESS_TOKEN_SUCCESS,
	REGISTER_FAILURE,
	REGISTER_REQUEST,
	REGISTER_SUCCESS,
	LOGOUT,
	CLEAR,
	CLEAR_AUTH,
	CHECK_ADMIN_REQUEST,
	CHECK_ADMIN_SUCCESS,
	CHECK_ADMIN_FAILURE,
	FORGOT_REQUEST,
	FORGOT_SUCCESS,
	FORGOT_FAILURE,
	RESET_REQUEST,
	RESET_SUCCESS,
	RESET_FAILURE,
} from '../factory/types'

const initialState = {
	pending: false,
	success: false,
	error: '',
	organization: null,
	role: null,
	userName: null,
	accessToken: '',
	adminOrg: null,
	user: {},
	refreshToken: ''
}

const authReducer = (state = {...initialState}, action) => {
	switch (action.type) {
	case RESET_ACCESS_TOKEN:
	case LOGIN_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		newState.error = ''
		return newState
	}
	// TODO replace explicit adminOrg with action.adminOrg
	case LOGIN_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.role = action.role
		newState.userName = action.userName
		newState.accessToken = action.accessToken
		newState.refreshToken = action.refreshToken
		newState.adminOrg = action.adminOrg
		newState.success = true
		newState.error = null
		newState.user = action.user
		return newState
	}
	case LOGIN_FAILURE: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		// NOTE Backend only returns an error message when the email doesn't exist
		newState.error = action.error || 'Incorrect Password'
		return newState
	}
	case RESET_ACCESS_TOKEN_SUCCESS: {
		return {...state, pending: false, accessToken: action.payload}
	}
	case RESET_ACCESS_TOKEN_FAIL: {
		return {...state, pending: false, accessToken: '', error: action.payload}
	}
	case REGISTER_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		newState.error = ''
		return newState
	}
	case REGISTER_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		newState.user = action.user
		newState.accessToken = action.accessToken
		return newState
	}
	case REGISTER_FAILURE: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.error
		return newState
	}
	case LOGOUT: {
		return {}
	}
	case FORGOT_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case FORGOT_FAILURE: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = action.error
		newState.success = false
		return newState
	}
	case FORGOT_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		return newState
	}
	case RESET_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case RESET_FAILURE: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = action.error
		newState.success = false
		return newState
	}
	case RESET_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		return newState
	}
	case CLEAR: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = null
		return newState
	}
	case CLEAR_AUTH: {
		return {...initialState}
	}
	case CHECK_ADMIN_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case CHECK_ADMIN_FAILURE: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.error
		return newState
	}
	case CHECK_ADMIN_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		return newState
	}
	default:
		return state
	}
}

export default authReducer
