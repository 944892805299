export default {
	dustGray: '#9b9b9b',
	wildSand: '#f4f4f4',
	white: '#FFFFFF',
	yellow: '#ffc107',
	crusta: '#F7772F',
	topBar: '#ffcc33',
	mineShaft: '#2c2c2c',
	sunglow: '#ffcc33',
	salomie: '#FEDF90',
	lightGray: '#d8d8d8',
	alto: '#DDDDDD',
}
