// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-mobile-donation-flow-js": () => import("../src/pages/mobileDonationFlow.js" /* webpackChunkName: "component---src-pages-mobile-donation-flow-js" */),
  "component---src-pages-register-org-index-js": () => import("../src/pages/register/org/index.js" /* webpackChunkName: "component---src-pages-register-org-index-js" */),
  "component---src-pages-register-funding-source-js": () => import("../src/pages/register/fundingSource.js" /* webpackChunkName: "component---src-pages-register-funding-source-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-discover-index-js": () => import("../src/pages/admin/discover/index.js" /* webpackChunkName: "component---src-pages-admin-discover-index-js" */),
  "component---src-pages-admin-discover-organization-js": () => import("../src/pages/admin/discover/organization.js" /* webpackChunkName: "component---src-pages-admin-discover-organization-js" */),
  "component---src-pages-admin-donations-js": () => import("../src/pages/admin/donations.js" /* webpackChunkName: "component---src-pages-admin-donations-js" */),
  "component---src-pages-admin-index-js": () => import("../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-profile-index-js": () => import("../src/pages/admin/profile/index.js" /* webpackChunkName: "component---src-pages-admin-profile-index-js" */),
  "component---src-pages-admin-profile-link-account-index-js": () => import("../src/pages/admin/profile/linkAccount/index.js" /* webpackChunkName: "component---src-pages-admin-profile-link-account-index-js" */),
  "component---src-pages-admin-profile-relink-js": () => import("../src/pages/admin/profile/relink.js" /* webpackChunkName: "component---src-pages-admin-profile-relink-js" */),
  "component---src-pages-forgot-js": () => import("../src/pages/forgot.js" /* webpackChunkName: "component---src-pages-forgot-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-register-complete-js": () => import("../src/pages/register/complete.js" /* webpackChunkName: "component---src-pages-register-complete-js" */),
  "component---src-pages-register-index-js": () => import("../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-register-monthly-min-js": () => import("../src/pages/register/monthlyMin.js" /* webpackChunkName: "component---src-pages-register-monthly-min-js" */),
  "component---src-pages-register-round-up-account-js": () => import("../src/pages/register/roundUpAccount.js" /* webpackChunkName: "component---src-pages-register-round-up-account-js" */),
  "component---src-pages-reset-js": () => import("../src/pages/reset.js" /* webpackChunkName: "component---src-pages-reset-js" */),
  "component---src-pages-unsubscribe-js": () => import("../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */)
}

