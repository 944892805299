import {
	GET_DISCOVER_REQUEST,
	GET_DISCOVER_SUCCESS,
	GET_DISCOVER_FAIL,
	UNSET_ACTIVE_ORG,
	SET_ACTIVE_ORG,
	GET_CATEGORIES_REQUEST,
	GET_CATEGORIES_SUCCESS,
	GET_CATEGORIES_FAIL,
	SET_ORGANIZATION_REQUEST,
	SET_ORGANIZATION_SUCCESS,
	SET_ORGANIZATION_FAIL,
	SEARCH_FOR_ORG,
	SEARCH_FOR_ORG_SUCCESS,
	SEARCH_FOR_ORG_FAIL,
	EMPTY_STRING_ORG_SEARCH
} from '../factory/types'

const initialState = {
	organizations: [],
	error: '',
	pending: false,
	categoryPending: false,
	categoryError: '',
	activeOrg: {},
	categories: [],
	searchResults: []
}

/**
 * Reducer which handles all events related to flashing error and notification messages
 */
const messageReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
	case SET_ORGANIZATION_REQUEST:
	case GET_DISCOVER_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		newState.error = ''
		newState.organizations = []
		return newState
	}
	case SEARCH_FOR_ORG: {
		const newState = Object.assign({}, state)
		newState.pending = true
		newState.error = ''
		return newState
	}
	case GET_DISCOVER_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.organizations = action.payload
		return newState
	}
	case GET_DISCOVER_FAIL: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = action.payload
		return newState
	}
	case SET_ACTIVE_ORG: {
		const newState = Object.assign({}, state)
		newState.activeOrg = action.payload
		return newState
	}
	case UNSET_ACTIVE_ORG: {
		const newState = Object.assign({}, state)
		newState.activeOrg = {}
		return newState
	}
	case GET_CATEGORIES_REQUEST: {
		const newState = Object.assign({}, state)
		newState.categoryPending = true
		newState.categoryError = ''
		return newState
	}
	case GET_CATEGORIES_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.categoryPending = false
		newState.categoryError = ''
		newState.categories = action.payload
		return newState
	}
	case GET_CATEGORIES_FAIL: {
		const newState = Object.assign({}, state)
		newState.categoryPending = false
		newState.categoryError = action.payload
		return newState
	}
	case SET_ORGANIZATION_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.activeOrg = {}
		newState.pending = false
		return newState
	}
	case SET_ORGANIZATION_FAIL: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = action.payload
		return newState
	}
	case SEARCH_FOR_ORG_FAIL: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = action.payload
		newState.searchResults = []
		return newState
	}
	case SEARCH_FOR_ORG_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = ''
		newState.searchResults = action.payload
		return newState
	}
	case EMPTY_STRING_ORG_SEARCH: {
		const newState = Object.assign({}, state)
		newState.searchResults = []
		return newState
	}
	default:
		return {
			...state
		}
	}
}

export default messageReducer
