import colors from './colors'
import customMessages from './customMessageEnums'

import {
	webTopBar,
	webFooter,
} from './layouts'

import {
	home,
	newOrganization,
	organizations,
	add,
	login,
	register,
	forgot,
	reset,
	mobileDonationFlow,
	project,
	onboarding,
	faqs
} from './pages'

const theme = {
	publicPaths: ['/login', '/register', '/forgot', '/reset', '/donations/mobile', '/unsubscribe', '/organization/'],
	defaultRedirect: '/login',
	colors,
	breakPoints: {
		xsmall: '767px',
		small: '769px',
		medium: '992px',
		large: '1200px',
	},
	layoutsContent: {
		webTopBar,
		webFooter,
	},
	pagesContent: {
		home,
		newOrganization,
		organizations,
		add,
		login,
		register,
		forgot,
		reset,
		mobileDonationFlow,
		project,
		onboarding,
		faqs
	},
	customMessages,
	images: {
		logo:
			'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/Wordmark/MarigoldType.png',
	},
	fonts: {
		primary: "'Work Sans', sans-serif",
		secondary: "'Frank Ruhl Libre', serif",
	},
	adminSidebar: {
		options: [
			{
				imageUrl: 'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/userDashboard/Impact.svg',
				text: 'My Impact',
				link: '/admin/',
			},
			{
				imageUrl: 'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/userDashboard/Discover.svg',
				text: 'Discover',
				link: '/admin/discover/',
			},
			{
				imageUrl: 'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/userDashboard/AccountPerformance.svg',
				text: 'My Round Ups',
				link: '/admin/donations/',
			},
			{
				imageUrl: 'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/userDashboard/profile.svg',
				text: 'My Profile',
				link: '/admin/profile',
			},
		],
	},
	body: {
		header: {
		},
	},
	footer: {
		menu: [
			{
				text: 'Home',
				link: '/',
			},
			{
				text: 'Nonprofits',
				link: '/nonprofits/',
			},
			{
				text: 'Google Ad Grant',
				link: '/marekting/',
			},
			{
				text: 'Privacy Policy',
				link: '/privacy/',
			},
			{
				text: 'Terms of Use',
				link: '/terms-and-conditions/',
			},
		],
		appleStore: {
			image:
				'https://flourishfiles.blob.core.windows.net/files/Marketing/AppBadges/app-store.svg',
			link:
				'https://itunes.apple.com/us/app/flourish-donations/id1356801492?mt=8',
		},
		playStore: {
			image:
				'https://flourishfiles.blob.core.windows.net/files/Marketing/AppBadges/google-play.png',
			link:
				'https://play.google.com/store/apps/details?id=com.flourish.flourish&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
		},
		copyRight:
			'© 2018 Copyright <strong>Flourish</strong>. All Rights Reserved',
		madeBy: 'Made with ♥ by The Flourish Team in ATX',
	},
}

export default theme
