import { navigate } from '@reach/router'
import axios from 'axios'
import {getUserProfile} from './user'
import {getError} from './error'
import * as Sentry from '@sentry/browser'
import {trackEvent} from '../util'

import {
	ADD_CC_REQUEST,
	ADD_CC_SUCCESS,
	ADD_CC_FAIL,
	ADD_BANK_SUCCESS,
	ADD_BANK_REQUEST,
	ADD_BANK_FAIL,
	DELETE_ACCOUNT_REQUEST,
	DELETE_ACCOUNT_FAIL,
	DELETE_ACCOUNT_SUCESS,
	RESET_BANK_STATE,
	API,
	TEST_HEADER,
} from './types'

export const linkCC = (data) => (dispatch) => {
	const {stripe, token, user, redirect} = data
	const {f_name, l_name, email} = user

	dispatch({type: ADD_CC_REQUEST})

	stripe.createToken({name: `${f_name} ${l_name}`, email})
		.then((res) => {
			const response = res.token
			const body = {
				token: response.id,
				mask: response.card.last4,
				exp_date: `${response.card.exp_month}/${response.card.exp_year}`
			}
			axios.post(`${API}/v1/mobile/updated/banks_and_cards/link_cc`, body, {headers: {authorization: token, flourish_test_header: TEST_HEADER}})
				.then(() => {
					dispatch({type: ADD_CC_SUCCESS})
					dispatch(getUserProfile(token))
					if(redirect.includes('/register/')) {
						trackEvent('register_linkCC_success', {mask: response.card.last4})
					}else {
						trackEvent('linkCC_success', {mask: response.card.last4})
					}

					if(redirect) {
						navigate(redirect)
					}else{
						navigate('/admin/profile/')
					}
				})
				.catch((err) => {
					const message = getError(err)
					if (message === 'This credit card was already added before.') {
						dispatch({type: ADD_CC_SUCCESS})
						if(redirect) {
							navigate(redirect)
						}else{
							navigate('/admin/profile/')
						}
					} else {
						if (err.response.status > 400) {
							Sentry.captureException(err)
						}
						dispatch({type: ADD_CC_FAIL, payload: getError(err) ? getError(err) : 'There was an issue adding your card. Please try again'})
					}
				})
		})
		.catch((err) => {console.log(err); dispatch({type: ADD_CC_FAIL, payload: err.message} )})
}

export const linkBank = (plaid_token, token, onSuccessCallback, onErrorCallback) => (dispatch) => {
	dispatch({type: ADD_BANK_REQUEST})
	axios.post(`${API}/v1/mobile/updated/banks_and_cards/link_account`, {token: plaid_token}, {headers: {authorization: token, flourish_test_header: TEST_HEADER}})
		.then(() => {
			dispatch({type: ADD_BANK_SUCCESS})
			if(onSuccessCallback.toString().includes('/register/')) {
				trackEvent('register_linkBank_success')
			}else {
				trackEvent('linkBank_success')
			}
			dispatch(getUserProfile(token))
			onSuccessCallback()
		})
		.catch((err) => {
			onErrorCallback()
			if (err.response.status > 400) {
				Sentry.captureException(err)
			}
			dispatch({type: ADD_BANK_FAIL, payload: getError(err) ? getError(err) : 'There was an issue adding your card. Please try again'})
		})
}

export const deleteAccount = (account_id, token, successCallback) => (dispatch) => {
	dispatch({type: DELETE_ACCOUNT_REQUEST})
	axios.post(`${API}/v1/mobile/updated/profile/deactivate_account`, {account_id}, {headers: {authorization: token, flourish_test_header: TEST_HEADER}})
		.then(() => {
			dispatch({type: DELETE_ACCOUNT_SUCESS})
			dispatch(getUserProfile(token))
			successCallback()
		})
		.catch((e) => {
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
			dispatch({type: DELETE_ACCOUNT_FAIL, payload: getError(e) ? getError(e) : 'Error removing account. Please try again.' })
		}
		)
}

export const resetBankState = () => (dispatch) => dispatch({type: RESET_BANK_STATE})