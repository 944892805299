
import axios from 'axios'
import {getError} from './error'
import { navigate } from '@reach/router'
import {getImpact} from './impact'
import * as Sentry from '@sentry/browser'

import {
	GET_DISCOVER_REQUEST,
	GET_DISCOVER_SUCCESS,
	GET_DISCOVER_FAIL,
	UNSET_ACTIVE_ORG,
	SET_ACTIVE_ORG,
	GET_CATEGORIES_REQUEST,
	GET_CATEGORIES_SUCCESS,
	GET_CATEGORIES_FAIL,
	SET_ORGANIZATION_REQUEST,
	SET_ORGANIZATION_SUCCESS,
	SET_ORGANIZATION_FAIL,
	SEARCH_FOR_ORG,
	SEARCH_FOR_ORG_SUCCESS,
	SEARCH_FOR_ORG_FAIL,
	EMPTY_STRING_ORG_SEARCH,
	CREATE_ORG_FAIL,
	API,
	TEST_HEADER,
} from './types'

export const getDiscover = (data) => (dispatch) => {
	const {category_id} = typeof(data) !== 'undefined' ? data : {}
	let url = '/v1/mobile/updated/cause/organizations'
	if (typeof(category_id) !== "undefined") {
		url = url + `?category_id=${category_id}`
	}

	dispatch({type: GET_DISCOVER_REQUEST})
	axios.get(`${API}${url}`, {headers: {flourish_test_header: TEST_HEADER}})
		.then((res) =>{
			dispatch({type: GET_DISCOVER_SUCCESS, payload: res.data.organizations})
		})
		.catch((e) =>{
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
			dispatch({type: GET_DISCOVER_FAIL, payload: getError(e) ? getError(e) : 'Error getting our organization list. Please try again. '})
		}

		)
}

export const getCategories = () => (dispatch) => {
	dispatch({type: GET_CATEGORIES_REQUEST})
	axios.get(`${API}/v1/mobile/updated/cause/categories`, {headers: {flourish_test_header: TEST_HEADER}})
		.then((res) =>{
			dispatch({type: GET_CATEGORIES_SUCCESS, payload: res.data.categories})
		})
		.catch((e) =>{
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
			dispatch({type: GET_CATEGORIES_FAIL, payload: getError(e) ? getError(e) : 'Error getting our categories. Please try again. '})
		})
}

export const getOrganization = (oid) => (dispatch) => {
	axios.get(`${API}/v1/mobile/updated/organization/get_organization?organization_id=${oid}`, {headers: {flourish_test_header: TEST_HEADER}})
		.then((res) =>{
			dispatch({type: SET_ACTIVE_ORG, payload: res.data})
		})
		.catch((e) =>{
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
			dispatch({type: GET_CATEGORIES_FAIL, payload: getError(e) ? getError(e) : 'Error getting your organization. Please try again. '})
		})
}

export const setActiveOrg = (org) => (dispatch) => {
	dispatch({type: SET_ACTIVE_ORG, payload: org})
}

export const unsetActiveOrg = () => (dispatch) => {
	dispatch({type: UNSET_ACTIVE_ORG})
}

export const setOrganization = (organization_id, project_ids, token, redirect = true) => (dispatch) => {
	dispatch({type: SET_ORGANIZATION_REQUEST})
	axios.post(`${API}/v1/mobile/updated/select_project/set_organization_and_projects`, {organization_id, project_ids}, {headers: {authorization: token, flourish_test_header: TEST_HEADER}})
		.then(() =>{
			dispatch({type: SET_ORGANIZATION_SUCCESS})
			dispatch(getImpact(token))
			if(redirect) {
				navigate('/admin/')
			}
		})
		.catch((e) =>{
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
			dispatch({type: SET_ORGANIZATION_FAIL, payload: getError(e) ? getError(e) : 'Error setting your organization. Please try again. '})
		}

		)
}

export const searchForOrg = (title) => (dispatch) => {
	if (title.length < 2) {
		dispatch({type: EMPTY_STRING_ORG_SEARCH})
		return
	}
	dispatch({type: SEARCH_FOR_ORG})
	axios.get(`${API}/v1/mobile/updated/search?title_part=${title}`)
		.then((res) => {
			const results = res.data.search_results.map((r) => r.model)
			dispatch({type: SEARCH_FOR_ORG_SUCCESS, payload: results})
		})
		.catch((e) => dispatch({type: SEARCH_FOR_ORG_FAIL, payload: getError(e) ? getError(e) : 'Error searching for organizations. Please try again.'}))
}

export const clearSearch = () => (dispatch) => {
	dispatch({type: EMPTY_STRING_ORG_SEARCH})
}

export const createNewOrg = (ein, callback = ()=>{}) => (dispatch) => {
	axios.post(`${API}/v1/mobile/updated/search/create_organization_guest`, {ein})
		.then((res) => {
			dispatch(getOrganization(res.data.organization_id))
			callback(res.data.organization_id)
		})
		.catch((e) => dispatch({type: CREATE_ORG_FAIL, payload: getError(e) ? getError(e) : 'Error. Please try again.'}))
}