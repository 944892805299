/* Action types */

export const STAGING = () =>  0 // process.env.STAGING === '1'
// console.log('IS_STAGING_ENV: ', STAGING())

export const PLAID_ENV = STAGING()
	? 'sandbox'
	: 'production'

export const API = STAGING()
	? 'https://flourishstage-api.azurewebsites.net/api'
	: 'https://flourish-api.azurewebsites.net/api'
export const PAYMENTSAPI = STAGING() ? 'http://localhost:5000' : 'https://flourishadmin-api.azurewebsites.net'
export const TEST_HEADER = STAGING() ? 1 : 0
export const STRIPEKEY = STAGING()
	? 'pk_test_Z8hrdj8NDlpOLl6GDswgXQz4'
	: 'pk_live_biKnRk1M9hPqZcbta2XHkZWp'

export const DEEPLINK = STAGING() ? 'https://flourish.test-app.link/ON8yAledSZ' : 'https://flourish.app.link/DTwPafsSYZ'

// Auth types
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'
export const LOGOUT = 'LOGOUT'
export const CHECK_ADMIN_REQUEST = 'CHECK_ADMIN_REQUEST'
export const CHECK_ADMIN_SUCCESS = 'CHECK_ADMIN_SUCCESS'
export const CHECK_ADMIN_FAILURE = 'CHECK_ADMIN_FAILURE'
export const FORGOT_REQUEST = 'FORGOT_REQUEST'
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS'
export const FORGOT_FAILURE = 'FORGOT_FAILURE'
export const RESET_REQUEST = 'RESET_REQUEST'
export const RESET_SUCCESS = 'RESET_SUCCESS'
export const RESET_FAILURE = 'RESET_FAILURE'
export const RESET_ACCESS_TOKEN = "RESET_ACCESS_TOKEN"
export const RESET_ACCESS_TOKEN_SUCCESS = "RESET_ACCESS_TOKEN_SUCCESS"
export const RESET_ACCESS_TOKEN_FAIL = "RESET_ACCESS_TOKEN_FAIL"

// Donate now types
export const GET_ORGS_REQUEST = 'GET_ORGS_REQUEST'
export const GET_ORGS_SUCCESS = 'GET_ORGS_SUCCESS'
export const GET_ORGS_FAILURE = 'GET_ORGS_FAILURE'
export const GET_ORG_CATEGORIES = 'GET_ORG_CATEGORIES'
export const GET_ORG_CATEGORIES_FAIL = 'GET_ORG_CATEGORIES_FAIL'
export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST'
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS'
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE'
export const SELECT_ORG = 'SELECT_ORG'
export const SELECT_PROJ = 'SELECT_PROJ'
export const SELECT_PROJECTS = 'SELECT_PROJECTS'
export const SET_ORG_SUCCESS = 'SET_ORG_SUCCESS'
export const SET_ORG_REQUEST = 'SET_ORG_REQUEST'
export const SET_ORG_FAILURE = 'SET_ORG_FAILURE'
export const SET_PROJ_SUCCESS = 'SET_PROJ_SUCCESS'
export const SET_PROJ_REQUEST = 'SET_PROJ_REQUEST'
export const SET_PROJ_FAILURE = 'SET_PROJ_FAILURE'
export const SET_DAILY_DONATION_REQUEST = 'SET_DAILY_DONATION_REQUEST'
export const SET_DAILY_DONATION_SUCCESS = 'SET_DAILY_DONATION_SUCCESS'
export const SET_DAILY_DONATION_FAILURE = 'SET_DAILY_DONATION_FAILURE'
export const CLEAR_ORG_AND_PROJ = 'CLEAR_ORG_AND_PROJ'
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS'
export const CLEAR_SELECTED_PROJ = 'CLEAR_SELECTED_PROJ'
export const DONATE_NOW_SUCCESS = 'DONATE_NOW_SUCCESS'
export const DONATE_NOW_REQUEST = 'DONATE_NOW_REQUEST'
export const DONATE_NOW_FAILURE = 'DONATE_NOW_FAILURE'
export const ONE_TIME_DONATION_AUTHED_REQUEST =
	'ONE_TIME_DONATION_AUTHED_REQUEST'
export const ONE_TIME_DONATION_AUTHED_SUCCESS =
	'ONE_TIME_DONATION_AUTHED_SUCCESS'
export const ONE_TIME_DONATION_AUTHED_FAILURE =
	'ONE_TIME_DONATION_AUTHED_FAILURE'
export const CLEAR_DONATE_NOW = 'CLEAR_DONATE_NOW'
export const ONE_TIME_DONATION_REQUEST = 'ONE_TIME_DONATION_REQUEST'
export const ONE_TIME_DONATION_FAIL = 'ONE_TIME_DONATION_FAIL'

// User Types
export const EXCHANGE_TOKEN_SUCCESS = 'EXCHANGE_TOKEN_SUCCESS'
export const LINK_BANK_SUCCESS = 'LINK_BANK_SUCCESS'
export const LINK_BANK_FAILURE = 'LINK_BANK_FAILURE'
export const LINK_BANK_REQUEST = 'LINK_BANK_REQUEST'
export const LINK_CREDIT_CARD_SUCCESS = 'LINK_CREDIT_CARD_SUCCESS'
export const LINK_CREDIT_CARD_FAILURE = 'LINK_CREDIT_CARD_FAILURE'
export const LINK_CREDIT_CARD_REQUEST = 'LINK_CREDIT_CARD_REQUEST'
export const GET_ACCOUNTS_SUCCESS = 'GET_LINKED_ACCOUNTS_SUCCESS'
export const GET_ACCOUNTS_FAIL = 'GET_ACCOUNTS_FAIL'
export const GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST'
export const SET_DONATION_ACCOUNT_SUCCESS = 'SET_DONATION_ACCOUNT_SUCCESS'
export const USER_ERROR = 'USER_ERROR'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_LOADING = 'USER_LOADING'

// Impact types
export const GET_IMPACT_REQUEST = 'GET_IMPACT_REQUEST'
export const GET_IMPACT_SUCCESS = 'GET_IMPACT_SUCCESS'
export const GET_IMPACT_FAIL = 'GET_IMPACT_FAIL'
export const SET_PROJECTS_REQUEST = 'SET_PROJECTS_REQUEST'
export const SET_PROJECTS_FAIL = 'SET_PROJECTS_FAIL'
export const SET_PROJECTS_SUCCESS = 'SET_PROJECTS_SUCCESS'
export const ONE_TIME_DONATION_SUCCESS = 'ONE_TIME_DONATION_SUCCESS'

// Discover types
export const GET_DISCOVER_REQUEST = 'GET_DISCOVER_REQUEST'
export const GET_DISCOVER_SUCCESS = 'GET_DISCOVER_SUCCESS'
export const GET_DISCOVER_FAIL = 'GET_DISCOVER_FAIL'
export const UNSET_ACTIVE_ORG = 'UNSET_ACTIVE_ORG'
export const SET_ACTIVE_ORG = 'SET_ACTIVE_ORG'
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL'
export const SET_ORGANIZATION_REQUEST = 'SET_ORGANIZATION_REQUEST'
export const SET_ORGANIZATION_SUCCESS = 'SET_ORGANIZATION_SUCCESS'
export const SET_ORGANIZATION_FAIL = 'SET_ORGANIZATION_FAIL'
export const SEARCH_FOR_ORG = 'SEARCH_FOR_ORG'
export const SEARCH_FOR_ORG_SUCCESS = 'SEARCH_FOR_ORG_SUCCESS'
export const SEARCH_FOR_ORG_FAIL = "SEARCH_FOR_ORG_FAIL"
export const EMPTY_STRING_ORG_SEARCH = 'EMPTY_STRING_ORG_SEARCH'
export const CREATE_ORG_FAIL = 'CREATE_ORG_FAIL'

// Account Performance Types
export const GET_ROUNDUPS_REQUEST = 'GET_ROUNDUPS_REQUEST'
export const GET_ROUNDUPS_SUCCESS = 'GET_ROUNDUPS_SUCCESS'
export const GET_ROUNDUPS_FAIL = 'GET_ROUNDUPS_FAIL'

// User Profile
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL'
export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST'
export const DELETE_ACCOUNT_FAIL = 'DELETE_ACCOUNT_FAIL'
export const DELETE_ACCOUNT_SUCESS = 'DELETE_ACCOUNT_SUCESS'
export const SET_MAX_REQUEST = 'SET_MAX_REQUEST'
export const SET_MIN_REQUEST = 'SET_MIN_REQUEST'
export const SET_MAX_SUCCESS = 'SET_MAX_SUCCESS'
export const SET_MIN_SUCCESS = 'SET_MIN_SUCCESS'
export const SET_MAX_FAIL = 'SET_MAX_FAIL'
export const SET_MIN_FAIL = 'SET_MIN_FAIL'
export const SET_ROUNDUP_REQUEST = 'SET_ROUNDUP_REQUEST'
export const SET_ROUNDUP_SUCCESS = 'SET_ROUNDUP_SUCCESS'
export const SET_ROUNDUP_FAIL = 'SET_ROUNDUP_FAIL'

// link banking
export const ADD_CC_REQUEST = 'ADD_CC_REQUEST'
export const ADD_CC_SUCCESS = 'ADD_CC_SUCCESS'
export const ADD_CC_FAIL = 'ADD_CC_FAIL'
export const ADD_BANK_SUCCESS = 'ADD_BANK_SUCCESS'
export const ADD_BANK_REQUEST = 'ADD_BANK_SUCCESS'
export const ADD_BANK_FAIL = 'ADD_BANK_SUCCESS'
export const RESET_BANK_STATE = 'RESET_BANK_STATE,'

// Notification types
export const ERROR = 'ERROR'
export const MESSAGE = 'MESSAGE'
export const CLEAR = 'CLEAR'

// Admin actions
export const GET_ORG_REQUEST = 'GET_ORG_REQUEST'
export const GET_ORG_SUCCESS = 'GET_ORG_SUCCESS'
export const GET_ORG_FAILURE = 'GET_ORG_FAILURE'


export const ADD_ORG_SUCCESS = 'ADD_ORG_SUCCESS'
export const ADD_ORG_FAILURE = 'ADD_ORG_FAILURE'
export const ADD_ORG_REQUEST = 'ADD_ORG_REQUEST'
export const EDIT_ORG_SUCCESS = 'EDIT_ORG_SUCCESS'
export const EDIT_ORG_FAILURE = 'EDIT_ORG_FAILURE'
export const EDIT_ORG_REQUEST = 'EDIT_ORG_REQUEST'
export const EDIT_ORG_VALS = 'EDIT_ORG_VALS'
export const ADD_PROJ_SUCCESS = 'ADD_PROJ_SUCCESS'
export const ADD_PROJ_FAILURE = 'ADD_PROJ_FAILURE'
export const ADD_PROJ_REQUEST = 'ADD_PROJ_REQUEST'
export const DELETE_PROJ_SUCCESS = 'DELETE_PROJ_SUCCESS'
export const DELETE_PROJ_FAILURE = 'DELETE_PROJ_FAILURE'
export const DELETE_PROJ_REQUEST = 'DELETE_PROJ_REQUEST'
export const EDIT_PROJ_REQUEST = 'EDIT_PROJ_REQUEST'
export const EDIT_PROJ_FAILURE = 'EDIT_PROJ_FAILURE'
export const EDIT_PROJ_SUCCESS = 'EDIT_PROJ_SUCCESS'

export const CLEAR_ORG = 'CLEAR_ORG'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const CLEAR_AUTH = 'CLEAR_AUTH'
export const CHANGE_SELECTED_PROJECT = 'CHANGE_SELECTED_PROJECT'
export const SET_INACTIVE_SUCCESS = 'SET_INACTIVE_SUCCESS'
export const SET_INACTIVE_FAILURE = 'SET_INACTIVE_FAILURE'
export const SET_INACTIVE_REQUEST = 'SET_INACTIVE_REQUEST'
export const SET_ACTIVE_FAILURE = 'SET_ACTIVE_FAILURE'
export const SET_ACTIVE_SUCCESS = 'SET_ACTIVE_SUCCESS'
export const SET_ACTIVE_REQUEST = 'SET_ACTIVE_REQUEST'
export const ADD_ADMIN_REQUEST = 'ADD_ADMIN_REQUEST'
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS'
export const ADD_ADMIN_FAILURE = 'ADD_ADMIN_FAILURE'
export const SEARCH_USERS_EMAIL_SUCCESS = 'SEARCH_BY_EMAIL_SUCCESS'
export const SEARCH_USERS_EMAIL_REQUEST = 'SEARCH_USERS_EMAIL_REQUEST'
export const CLEAR_USERS = 'CLEAR_USERS'

// Analytics
export const GET_DONATIONS_SUCCESS = 'GET_DONATIONS_SUCCESS'
export const GET_DONATIONS_FAILURE = 'GET_DONATIONS_FAILURE'
export const GET_DONATIONS_REQUEST = 'GET_DONATIONS_REQUEST'

// Subscriptions
export const START_SUBSCRIPTION_REQUEST = 'START_SUBSCRIPTION_REQUEST'
export const START_SUBSCRIPTION_SUCCESS = 'START_SUBSCRIPTION_SUCCESS'
export const START_SUBSCRIPTION_FAIL = 'START_SUBSCRIPTION_FAIL'

// Onboarding checklist
export const COMPLETE_TASK_REQUEST = 'COMPLETE_TASK_REQUEST'
export const COMPLETE_TASK_SUCCESS = 'COMPLETE_TASK_SUCCESS'
export const COMPLETE_TASK_FAIL = 'COMPLETE_TASK_FAIL'
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS'
export const GET_TASKS_FAIL = 'GET_TASKS_FAIL'
export const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST'

// invoices
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS'
export const ACCOUNTING_ERROR = 'ACCOUNTING_ERROR'
export const ACCOUNTING_PENDING = 'ACCOUNTING_PENDING'
export const REQUEST_ERROR = 'REQUEST_ERROR'
export const MONEY_REQUEST_SUCCESS = 'MONEY_REQUEST_SUCCESS'

// One Click
export const MESSAGE_REQUEST = 'UPDATE_MESSAGE_REQUEST'
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS'
export const UPDATE_MESSAGE_FAIL = 'UPDATE_MESSAGE_FAIL'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const TEST_SEND_SUCCESS = 'TEST_SEND_SUCCESS'
export const TEST_SEND_FAIL = 'TEST_SEND_FAIL'