module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Flourish-User-Web-App","short_name":"Flourish","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/bigLogo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://a5f2dfc9021348748d3a7bac2f87d7ea@sentry.io/1871315","release":"Flourish-User-Web-App@1.6.1","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"AgAOhRik4m1blm8LSCtOmPyFhWZkj3wC","devKey":"dQGTxVsTwfAeWPwrY7A50TrGYDpSzSmP","trackPage":false,"delayLoad":false,"delayLoadTime":1000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
