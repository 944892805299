
import { navigate } from '@reach/router'
import {
	isAuthenticated,
	isBrowser,
	getUserInfo,
} from './auth'

import theme from '../theme/content'

const pathName = typeof window !== 'undefined' && window.location.pathname.replace(/\/$/, '')

var redirectTo = theme.defaultRedirect

const publicPaths = theme.publicPaths

const routeValidate = () => {
	const profile = getUserInfo()

	if (profile.userRole) {
		if (!isAuthenticated() && isBrowser && !publicPaths.indexOf(pathName)) {
			redirectTo = 'login'

			if (profile.userRole !== 1) {
				redirectTo = '/login'
			}

			if (profile.userRole !== 2 && !pathName.includes('admin')) {
				redirectTo = '/admin'
			}

			if (profile.userRole !== 2 && pathName.includes('/register')) {
				redirectTo = pathName
			}
			navigate(redirectTo)
		}

		if (isAuthenticated() && isBrowser) {
			redirectTo = null

			if (profile.userRole === 1 && !pathName.includes('admin') && !publicPaths.indexOf(pathName)) {
				redirectTo  = '/admin'
			}

			if (profile.userRole === 1 && pathName === '/') {
				redirectTo  = '/admin'
			}

			navigate(redirectTo)
		}
	} else {
		if (publicPaths.indexOf(pathName) === -1 && !pathName.includes('/organization') && !pathName.includes('/register') ) {
			navigate(`/login?redirect=${pathName}&loginType=1`)
		}
	}
}

export default routeValidate
