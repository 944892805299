export default {
	title: 'Register for Flourish',
	bg: {
		color: false,
		image:
			'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/Logomark/logo_opacity.png',
		size: '1000px 1000px',
		position: '-190px -375px',
	},
	registerForm: {
		steps: {
			first: [
				[
					{
						label: 'First Name',
						name: 'f_name',
						placeholder: 'Braden',
						field: 'default',
						type: 'text',
						maxWidth: '50%',
						style: {
							background: 'inherit',
						},
					},
					{
						label: 'Last Name',
						name: 'l_name',
						placeholder: 'Smith',
						field: 'default',
						type: 'text',
						maxWidth: '50%',
						style: {
							background: 'inherit',
						},
					},
				],
				[
					{
						label: 'Email',
						name: 'email',
						placeholder: 'demo@flourishchange.com',
						field: 'default',
						type: 'email',
						maxWidth: '100%',
						style: {
							background: 'inherit',
						},
					},
				],
				[
					{
						label: 'Phone',
						name: 'phone',
						placeholder: '(737) 228-3386',
						field: 'default',
						type: 'text',
						maxWidth: '100%',
						style: {
							background: 'inherit',
						},
					},
				],
				[
					{
						label: 'Password',
						name: 'password',
						placeholder: '••••••••••••',
						field: 'default',
						type: 'password',
						maxWidth: '50%',
						style: {
							height: 28,
							background: 'inherit',
						},
					},
					{
						label: 'Confirm Password',
						name: 'password_confirm',
						placeholder: '••••••••••••',
						field: 'default',
						type: 'password',
						maxWidth: '50%',
						style: {
							height: 28,
							background: 'inherit',
						},
					},
				],
			],
			second: [
				[
					{
						label: 'Organizations',
						name: 'organization',
						field: 'select',
						options: null,
						maxWidth: '100%',
					}
				]
			],
			third: [
				[
					{
						label: '0.25$',
						name: 'daily_donation',
						field: 'checkbox',
						type: 'radio',
						maxWidth: '20%',
						value: 0.25,
						style: {
							marginTop: '0.5em'
						}
					},
					{
						label: '0.5$',
						name: 'daily_donation',
						field: 'checkbox',
						type: 'radio',
						maxWidth: '20%',
						value: 0.5,
						style: {
							marginTop: '0.5em'
						}
					},
					{
						label: '0.75$',
						name: 'daily_donation',
						field: 'checkbox',
						type: 'radio',
						maxWidth: '20%',
						value: 0.75,
						style: {
							marginTop: '0.5em'
						}
					},
					{
						label: '1$',
						name: 'daily_donation',
						field: 'checkbox',
						type: 'radio',
						maxWidth: '20%',
						value: 1,
						style: {
							marginTop: '0.5em'
						}
					},
					{
						label: '2$',
						name: 'daily_donation',
						field: 'checkbox',
						type: 'radio',
						maxWidth: '20%',
						value: 2,
						style: {
							marginTop: '0.5em'
						}
					},
					{
						label: 'Custom',
						name: 'daily_donation',
						field: 'checkbox',
						type: 'radio',
						maxWidth: '20%',
						value: 0,
						style: {
							marginTop: '0.5em'
						}
					},
				]
			]
		},
		submitField: {
			text: 'Sign Up',
		},
	},
	login: {
		text: 'Log in',
		link: 'login',
	},
}
