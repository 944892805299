import {
	ADD_CC_REQUEST,
	ADD_CC_SUCCESS,
	ADD_CC_FAIL,
	ADD_BANK_SUCCESS,
	ADD_BANK_REQUEST,
	RESET_BANK_STATE,
	ADD_BANK_FAIL,
	DELETE_ACCOUNT_REQUEST,
	DELETE_ACCOUNT_FAIL,
	DELETE_ACCOUNT_SUCESS
} from '../factory/types'

const initialState = {
	pending: false,
	error: '',
	bank_pending: false
}

const bankReducer = (state = initialState, action) => {
	switch(action.type) {
	case DELETE_ACCOUNT_REQUEST:
	case ADD_CC_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		newState.error = ''
		return newState
	}
	case ADD_BANK_REQUEST: {
		const newState = Object.assign({}, state)
		newState.bank_pending = true
		newState.error = ''
		return newState
	}
	case ADD_BANK_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.bank_pending = false
		newState.error = ''
		return newState
	}
	case DELETE_ACCOUNT_SUCESS:
	case ADD_CC_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.bank_pending = false
		return newState
	}
	case DELETE_ACCOUNT_FAIL:
	case ADD_BANK_FAIL:
	case ADD_CC_FAIL: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.bank_pending = false
		newState.error = action.payload
		return newState
	}
	case RESET_BANK_STATE: {
		return initialState
	}
	default:
		return state
	}
}

export default bankReducer