import {persistCombineReducers} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './authReducer'
import adminReducer from './adminReducer'
import donateNowReducer from './donateNowReducer'
import notificationReducer from './notificationReducer'
import userReducer from './userReducer'
import onboardingReducer from './onboardingReducer'
import discoverReducer from './discoverReducer'
import accountReducer from './accountPerformanceReducer'
import bankReducer from './bankReducer'

const config = {
	key: 'rootAdmin',
	storage,
	timeout: null,
}

const rootReducer = persistCombineReducers(config, {
	authState: authReducer,
	adminState: adminReducer,
	donateNowState: donateNowReducer,
	userState: userReducer,
	notificationState: notificationReducer,
	onboardingState: onboardingReducer,
	discoverState: discoverReducer,
	accountState: accountReducer,
	bankState: bankReducer
})

export default rootReducer
