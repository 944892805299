import colors from '../colors'

const theme = {
	colors: {
		...colors,

		black: colors.mineShaft,
		topBar: colors.sunglow,
		menuLink: colors.crusta,
		buttons: colors.sunglow,
		buttonsHovered: colors.salomie,
		buttonsShadow: 'rgba(74, 74, 74, 0.2)',
		link: colors.crusta,
	},
	breakPoints: {
		xsmall: '767px',
		small: '769px',
		medium: '992px',
		large: '1200px',
	},
	fonts: {
		primary: "'Work Sans', sans-serif",
		secondary: "'Frank Ruhl Libre', serif",
	},
	images: {
		logo:
			'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/Wordmark/MarigoldType.png',
		isotype: 'https://flourishchange.com/static/media/logo.13c62929.png',
	},
	mainMenu: [
		{
			name: 'Home',
			link: 'https://www.flourishchange.com',
		},
		{
			name: 'Features',
			link: 'https://www.flourishchange.com#features',
		},
		{
			name: 'Nonprofits',
			link: 'https://www.flourishchange.com/nonprofits',
		},
		{
			name: 'Pricing',
			link: 'https://www.flourishchange.com/pricing',
		},
		{
			name: 'Login',
			link: '/login',
		},
		{
			name: 'Nonprofit Login',
			link: `https://dashboard.flourishchange.com/login`
		},
		{
			name: 'Donor Sign In & Sign Up',
			link: '/login',
			highlight: true
		}
	],
}

export default theme
