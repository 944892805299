import axios from 'axios'
import {getError} from './error'
import { navigate } from '@reach/router'
import {getProjects} from './donateNow'
import {getUserProfile} from './user'
import * as Sentry from '@sentry/browser'


import {
	GET_IMPACT_REQUEST,
	GET_IMPACT_SUCCESS,
	GET_IMPACT_FAIL,
	SET_PROJECTS_REQUEST,
	SET_PROJECTS_FAIL,
	SET_PROJECTS_SUCCESS,
	ONE_TIME_DONATION_REQUEST,
	ONE_TIME_DONATION_SUCCESS,
	ONE_TIME_DONATION_FAIL,
	API,
	TEST_HEADER,
} from './types'

export const getImpact = (token) => (dispatch) => {
	dispatch({type: GET_IMPACT_REQUEST})
	return axios.get(`${API}/v1/mobile/updated/impact`, {headers: {authorization: token, flourish_test_header: TEST_HEADER}})
		.then((res) =>{
			const organization_id = res.data.organization.organization_id
			dispatch(getProjects(organization_id))
			dispatch(getUserProfile(token))
			dispatch({type: GET_IMPACT_SUCCESS, payload: res.data})
			return true
		})
		.catch((e) =>{
			if (e.response.status === 401) {
				localStorage.clear()
				navigate('/login/')
			}
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
			dispatch({type: GET_IMPACT_FAIL, payload: getError(e) ? getError(e) : 'There was an error logging in. Please try again. '})
		}

		)
}

export const selectProject = (project_ids, token) => (dispatch) => {
	dispatch({type: SET_PROJECTS_REQUEST})
	axios.post(`${API}/v1/user/set_projects`, {project_ids}, {headers: {authorization: token, flourish_test_header: TEST_HEADER}})
		.then((res) =>{
			dispatch({type: SET_PROJECTS_SUCCESS, payload: res.data.data})
			dispatch(getImpact(token))
		})
		.catch((e) =>{
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
			dispatch({type: SET_PROJECTS_FAIL, payload: getError(e) ? getError(e) : 'There was an error logging in. Please try again. '})
		}

		)
}

// TODO
export const giveOneTimeDonation = (data, token) => (dispatch) => {
	dispatch({type: ONE_TIME_DONATION_REQUEST})

	axios.post(`${API}`, data, {headers: {authorization: token, flourish_test_header: TEST_HEADER}})
		.then((res) =>{
			dispatch({type: ONE_TIME_DONATION_SUCCESS})
			dispatch(getImpact(token))
		})
		.catch((e) =>{
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
			dispatch({type: ONE_TIME_DONATION_FAIL, payload: getError(e) ? getError(e) : 'There was an error logging in. Please try again. '})
		})
}