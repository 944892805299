
const incomplete = {status: false, message: '', href: ''}

export default function incompleteAccount(props) {
	const {organization, accountList} = props

	if (checkDNE(organization)) {
		incomplete.status = true
		incomplete.href = '/admin/discover'
		incomplete.message = "You haven't selected a cause yet!"
		return incomplete
	}

	// Check if the user has linked accounts
	if (checkDNE(accountList)) {
		incomplete.status = true
		incomplete.href = '/admin/profile/fundingSource/'
		incomplete.message = "You haven't added a funding source!"
		return incomplete
	}
	if (accountList.length > 0) {
		const hasPaymentAccount = accountList.filter((acc) => acc.is_payment).length > 0
		const hasChargeable = accountList.filter((acc) => acc.is_chargable).length > 0
		const hasRoundUps = accountList.filter((acc) => acc.is_trackable).length > 0

		if (!hasChargeable) {
			incomplete.status = true
		    incomplete.href = '/admin/profile/linkAccount/'
			incomplete.message = "You haven't added a funding source!"
			return incomplete
		}

		if (!hasPaymentAccount) {
			incomplete.status = true
		    incomplete.href = '/admin/profile/'
			incomplete.message = "You haven't selected a payment account"
			return incomplete
		}

		if (!hasRoundUps) {
			incomplete.status = true
		    incomplete.href = `/admin/profile/linkAccount/?chargeable=${hasChargeable}`
			incomplete.message = "You don't have any roundup accounts"
			return incomplete
		}
	}
	return incomplete
}

function checkDNE(param) {
	if (!param) {
		return true
	}
	switch (typeof(param)) {
	case ('object'): return Object.keys(param).length === 0
	case ('array') : return Object.length === 0
	default: return typeof(param) === 'undefined' || typeof(param) === 'null'
	}
}