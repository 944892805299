import {
	login as auth,
} from '../factory'

export const isBrowser = typeof window !== 'undefined'

// To speed things up, we’ll keep the profile stored unless the user logs out.
// This prevents a flicker while the HTTP round-trip completes.
let profile = false

const setSession = authResult => {
	if (!isBrowser) {
		return
	}

	const expiresAt = JSON.stringify(3.6e6 + new Date().getTime())
	const refreshExpires = JSON.stringify(3.6e10 + new Date().getTime())

	localStorage.setItem('access_token', authResult.access_token)
	localStorage.setItem('refresh_token', authResult.refresh_token)
	localStorage.setItem('refresh_token_expires_at', refreshExpires)
	localStorage.setItem('user_role', parseInt(authResult.role, 10))
	localStorage.setItem('user_name', authResult.userName)
	localStorage.setItem('user_id', parseInt(authResult.user_id, 10))
	localStorage.setItem('user_email', authResult.email)
	localStorage.setItem('expires_at', expiresAt)

	// eslint-disable-next-line
	return true
}

export const login = (email, pass, redirect) => {
	if (!isBrowser) {
		return
	}

	// eslint-disable-next-line
	return dispatch => dispatch(auth(email, pass, setSession, cleanSession, redirect))
}

export const cleanSession = () => {
	if (isBrowser) {
		localStorage.clear()
	}

	profile = false
}

export const isAuthenticated = () => {
	if (!isBrowser) {
		// For SSR, we’re never authenticated.
		return false
	}

	const expiresAt = JSON.parse(localStorage.getItem('refresh_token_expires_at'))

	return new Date().getTime() < expiresAt
}

export const getAccessToken = () => {
	if (!isBrowser) {
		return ''
	}

	return localStorage.getItem('access_token')
}

export const updateToken = (token) => {
	const expiresAt = JSON.stringify(3.6e6 + new Date().getTime())
	localStorage.setItem('access_token', token)
	localStorage.setItem('expires_at', expiresAt)
}

export const getUserInfo = () => {
	if (profile) {
		return profile
	}

	if (!isAuthenticated()) {
		return {}
	}

	profile = {
		accessToken: localStorage.getItem('access_token'),
		userRole: parseInt(localStorage.getItem('user_role'), 10),
		userName: localStorage.getItem('user_name'),
		expiresAt: localStorage.getItem('expires_at'),
		uid: parseInt(localStorage.getItem('user_id'), 10),
		user_email: localStorage.getItem('user_email'),
	}

	return profile
}
