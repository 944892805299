import axios from 'axios'

import {getError} from './error'
import {logout} from './auth'
import * as Sentry from '@sentry/browser'
import {trackEvent} from '../util'

import {
	GET_ACCOUNTS_SUCCESS,
	GET_ACCOUNTS_FAIL,
	GET_ACCOUNTS_REQUEST,
	SET_MAX_REQUEST,
	SET_MIN_REQUEST,
	SET_MAX_SUCCESS,
	SET_MIN_SUCCESS,
	SET_MAX_FAIL,
	SET_MIN_FAIL,
	SET_ROUNDUP_REQUEST,
	SET_ROUNDUP_SUCCESS,
	SET_ROUNDUP_FAIL,
	EXCHANGE_TOKEN_SUCCESS,
	GET_PROFILE_REQUEST,
	GET_PROFILE_SUCCESS,
	GET_PROFILE_FAIL,
	USER_ERROR,
	USER_SUCCESS,
	USER_LOADING,
	API,
	TEST_HEADER,
} from './types'

// Action to authorize account during 1-time donations
export const exchangeToken = (otp_token, key) =>  (dispatch) => {
	axios.post(`${API}/v1/user/exchange_otp_token`, {otp_token, key}, {
		headers: {
			'Content-Type': 'application/json',
			'flourish-test-header': TEST_HEADER
		}
	})
		.then((res) => {
			dispatch({
				type: EXCHANGE_TOKEN_SUCCESS,
				payload: `Bearer ${res.data.data}`
			})
		})
		.catch((err) => {
			if (err.response.status > 400) {
				Sentry.captureException(err)
			}
		})
}

export const getAccounts = (token) => (dispatch) => {
	dispatch({type: GET_ACCOUNTS_REQUEST})

	axios.get(`${API}/v1/account/list?is_linked=true`, { headers: {
		authorization: token,
		'flourish-test-header': TEST_HEADER
	}})
		.then((res) => {
			dispatch({
				type: GET_ACCOUNTS_SUCCESS,
				payload: res.data.data
			})
		})
		.catch((err) => {
			dispatch({type: GET_ACCOUNTS_FAIL})
			if (err.response.status > 400) {
				Sentry.captureException(err)
			}
		})
}

export const unauthConfigDonations = (otp_token, key) => (dispatch) => {
	axios.post(`${API}/v1/user/exchange_otp_token`, {otp_token, key}, {
		headers: {
			'Content-Type': 'application/json',
			'flourish-test-header': TEST_HEADER
		}
	})
		.then((res) => {
			const authToken = `Bearer ${res.data.data}`
			dispatch({
				type: EXCHANGE_TOKEN_SUCCESS,
				payload: authToken
			})
			return authToken
		})
		.then((token) => dispatch(getAccounts(token)))
		.catch((err) => {
			if (err.response.status > 400) {
				Sentry.captureException(err)
			}
		})
}

export const setDonationAccount = (account_id, value, token) => (dispatch) => {
	axios.post(`${API}/v1/mobile/updated/profile/set_is_payment`, {
		account_id,
		"is_payment": value}, {
		headers: {
			authorization: token,
			'Content-Type': 'application/json',
			'flourish-test-header': TEST_HEADER
		}
	}).then(() => {
		dispatch(getUserProfile(token))
		trackEvent('set_donation_account')
	})
		.catch((e) => {
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
		})
}

export const setDonationType = (params, token) => (dispatch) => {
	axios.post(`${API}/v1/mobile/updated/select_donation_type/set_donor_type`, params, {
		headers: {
			authorization: token,
			'Content-Type': 'application/json',
			'flourish-test-header': TEST_HEADER
		}
	})
		.then(() => {
			dispatch(getUserProfile(token))
			if (params.register) {
				trackEvent('register_set_donor_type', {donor_type: parseInt(params.donor_type, 10)})
			} else{
				trackEvent('set_donor_type', {previous_donor_type: params.previous_type, new_donor_type: parseInt(params.donor_type, 10)})
			}
		})
		.catch((e) => {
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
		})
}

export const unsubscribe = ({code}) => (dispatch) => {
	dispatch({type: USER_LOADING})
	axios.get(`${API}/v1/web/user/email_unsubscribe?code=${code}`, {
		headers: {
			'Content-Type': 'application/json',
			'flourish-test-header': TEST_HEADER
		}
	})
		.then(()=>{
			trackEvent('unsubscribed_from_emails')
			dispatch({type: USER_SUCCESS, payload: 'unsubscribe'})
		})
		.catch((err) => {
			if (err.response.status > 400) {
				Sentry.captureException(err)
			}
			dispatch({type: USER_ERROR, payload: getError(err)})
		})
}


export const getUserProfile = (token) => (dispatch) => {
	dispatch({type: GET_PROFILE_REQUEST})
	axios.get(`${API}/v1/mobile/updated/profile`, {headers: {authorization: token, flourish_test_header: TEST_HEADER}})
		.then((res) =>{
			console.log(res.data)
			dispatch({type: GET_PROFILE_SUCCESS, payload: res.data})
		}

		)
		.catch((e) =>{
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
			dispatch({type: GET_PROFILE_FAIL, payload: getError(e) ? getError(e) : 'Error getting profile. Try logging out and logging back in.'})
		} )
}

export const setMonthlyMax = (max, token) => (dispatch) => {
	dispatch({type: SET_MAX_REQUEST})
	axios.post(`${API}/v1/mobile/updated/profile/set_month_limit`, {month_limit: max}, {headers: {authorization: token, flourish_test_header: TEST_HEADER}})
		.then(() => {
			trackEvent('set_monthly_max', {max_limit: max})
			dispatch({type: SET_MAX_SUCCESS})
			dispatch(getUserProfile(token))
		})
		.catch((e) =>{
			if(e.response.status === 400) {
				logout('/admin/profile/?loginType=1')
			}
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
			dispatch({type: SET_MAX_FAIL, payload: getError(e) ? getError(e) : 'Error setting your monthly maximum donation. Please try again.'})
		} )
}

export const setMonthlyMin = (min, token, callback = ()=>{}) => (dispatch) => {
	dispatch({type: SET_MIN_REQUEST})
	axios.post(`${API}/v1/mobile/updated/user/set_month_min_limit`, {monthly_min_donation_limit: min}, {headers: {authorization: token, flourish_test_header: TEST_HEADER}})
		.then(() => {
			if (callback.toString().includes('/register/')) {
				trackEvent('register_set_monthly_min', {min_limit: min})
			} else {
				trackEvent('set_monthly_min', {min_limit: min})
			}
			dispatch({type: SET_MIN_SUCCESS})
			dispatch(getUserProfile(token))
			callback()
		})
		.catch((e) =>{
			if(e.response.status === 400) {
				logout('/admin/profile/?loginType=1')
			}
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
			dispatch({type: SET_MIN_FAIL, payload: getError(e) ? getError(e) : 'Error setting your monthly minimum donation. Please try again.'})
		} )
}

export const trackAccountForRoundups = (account_id, tracked, token) => (dispatch) => {
	dispatch({type: SET_ROUNDUP_REQUEST})
	axios.post(`${API}/v1/mobile/updated/profile/set_is_tracked_for_roundups`, {account_id, is_tracked_for_roundups: tracked}, {headers: {authorization: token, flourish_test_header: TEST_HEADER}})
		.then(() => {
			dispatch({type: SET_ROUNDUP_SUCCESS})
			dispatch(getUserProfile(token))
		})
		.catch((e) =>{
			if(e.response.status === 400) {
				logout('/admin/profile/?loginType=1')
			}
			if (e.response.status > 400) {
				Sentry.captureException(e)
			}
			dispatch({type: SET_ROUNDUP_FAIL, payload: getError(e) ? getError(e) : 'Error setting your monthly minimum donation. Please try again.'})
		} )
}