import {
	GET_ROUNDUPS_REQUEST,
	GET_ROUNDUPS_SUCCESS,
	GET_ROUNDUPS_FAIL,
} from '../factory/types'

const initialState = {
	pending: false,
	transactions: [],
	donations: [],
	daily_donations: [],
	total_sum: 0.0,
	error: ''
}

const accountPerformanceReducer = (state = initialState, action) => {
	switch(action.type) {
	case GET_ROUNDUPS_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		newState.error = ''
		return newState
	}
	case GET_ROUNDUPS_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.transactions = action.payload.transactions
		newState.donations = action.payload.donations
		newState.total_sum = action.payload.total_sum
		newState.daily_donations = action.payload.daily_deposits
		return newState
	}
	case GET_ROUNDUPS_FAIL: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = action.payload
		return newState
	}
	default:
		return state
	}
}

export default accountPerformanceReducer